<template>
  <div class="custom-dashboard-page app-container">
    <!-- <r-button @click="resetHandler">重置</r-button> -->
    <div class="cards-container" v-loading="loading">
      <div
        v-for="item in list"
        :key="item.id"
        class="item"
        :style="{
          width: cardWidthReander(item),
        }"
      >
        <SingleCard :dataset="item" />
      </div>
    </div>
    <HomeFAQ v-if="needFAQ" @close="FAQClose" />
  </div>
</template>

<script>
import SingleCard from "../components/singleCard.vue";
import { getUserPortal } from "@/api/ruge/bmsNew/dashboard.js";
import FAQ from "@/utils/FAQ.js";
import HomeFAQ from "@/components/FAQ/home.vue";

export default {
  name: "custom-dashboard-page",
  components: {
    SingleCard,
    HomeFAQ,
  },
  data() {
    return {
      loading: false,
      list: [],
      needFAQ: false,
    };
  },
  created() {
    this.initDatas();
    this.initFAQ();
  },
  methods: {
    resetHandler() {
      FAQ.updateFAQ("Home", 0);
      setTimeout(() => {
        FAQ.updateFAQ("TemplateList", 0);
      }, 1000);
      setTimeout(() => {
        FAQ.updateFAQ("CustomList", 0);
      }, 2000);
    },
    FAQClose(jumpFlag) {
      FAQ.updateFAQ("Home", 1);
      this.needFAQ = false;
      if (jumpFlag) {
        this.$router.push("/bms/dashboardEdit?layout=hide");
      }
    },
    initFAQ() {
      this.needFAQ = FAQ.isNeedFAQ("Home");
      console.log("this.needFAQ", this.needFAQ);
    },
    initDatas() {
      this.loading = true;
      getUserPortal({
        customType: "home",
      })
        .then((res) => {
          this.list = JSON.parse(res.customConfig);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cardWidthReander(item) {
      const marginMap = {
        1: 20,
        2: 10,
        3: 0,
      };
      const oneStep = 33.3;
      let finalWidth = 33.3;
      const cardSizeParse = JSON.parse(item.cardSize);
      finalWidth = cardSizeParse.mode * oneStep;
      return `calc(${finalWidth}% - ${marginMap[cardSizeParse.mode]}px)`;
    },
  },
};
</script>

<style lang="less" scoped>
.custom-dashboard-page {
  // width: 100%;
  // padding: 20px 40px;
  // max-height: calc(100vh - 90px);
  // overflow-y: auto;
  // overflow-x: hidden;
  padding: 20px 0px;
  background-color: #ffffff00;
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
  }
  .item {
    width: 33.3%;
    border-radius: 5px;
    color: #000;
  }
}
</style>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "custom-dashboard-page app-container" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "cards-container",
        },
        _vm._l(_vm.list, function (item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "item",
              style: {
                width: _vm.cardWidthReander(item),
              },
            },
            [_c("SingleCard", { attrs: { dataset: item } })],
            1
          )
        }),
        0
      ),
      _vm.needFAQ ? _c("HomeFAQ", { on: { close: _vm.FAQClose } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
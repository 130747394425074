<template>
  <div class="FAQ-component-home">
    <img
      class="menu-background img-background"
      src="@/assets/images/FAQ/home_1.png"
      alt=""
    />
    <img
      class="button-background img-background"
      @click="readSuccess"
      src="@/assets/images/FAQ/home_2.png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "FAQ-component-home",
  mounted() {
    this.bindEvent();
  },
  methods: {
    bindEvent() {
      const { innerWidth } = window;
      const RightDistance = 20;
      const TopDistance = 70;
      const TheXRight = innerWidth - RightDistance; // X 右边框
      const TheXLeft = innerWidth - RightDistance - 119; // X 左边框
      const TheYBottom = TopDistance + 53; // Y 下边框
      const TheYTop = TopDistance; // Y 上边框
      const Dom = $(".FAQ-component-home")[0];
      document.addEventListener("mousemove", (event) => {
        const { pageX, pageY } = event;
        if (
          pageX >= TheXLeft &&
          pageX <= TheXRight &&
          pageY >= TheYTop &&
          pageY <= TheYBottom
        ) {
          Dom.style.cursor = "pointer";
        } else {
          Dom.style.cursor = "default";
        }
      });
      document.addEventListener("click", (event) => {
        const { pageX, pageY } = event;
        if (
          pageX >= TheXLeft &&
          pageX <= TheXRight &&
          pageY >= TheYTop &&
          pageY <= TheYBottom
        ) {
          this.$emit("close", true);
        }
      });
    },
    readSuccess() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="less" scoped>
.FAQ-component-home {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background: #00000070;
  z-index: 99999;
  .img-background {
    position: absolute;
  }
  .menu-background {
    top: 70px;
    right: 20px;
  }
  .button-background {
    top: 331px;
    right: 279px;
    cursor: pointer;
  }
}
</style>